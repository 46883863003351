<template>
  <div v-if="raskDetailInfo">
    <div class="header"><span class="ret" @click="ret">任务管理/</span> <span>任务详情</span></div>
    <div class="header-one">
      <div class="rask-title">
        <div class="rt-left">
          <div class="rt-left-title">{{raskDetailInfo.title}}</div>
          <div class="rt-left-time">{{raskDetailInfo.createTime}}发布</div>
        </div>
        <div class="rt-right">
          <!-- <a-button v-if="raskDetailInfo.status==='13'" @click="raskEnd">结束报名</a-button> -->
          <a-button v-if="canCancel" @click="cancelRask" style="margin-left:12px">取消任务</a-button>
          <a-button v-if="raskDetailInfo.status!=='30'&&raskDetailInfo.status!=='50'" @click="closeRask" style="margin-left:12px">关闭任务</a-button>
        </div>
      </div>
      <div class="rask-content">
        <a-row :gutter="24">
          <a-col :span="8" class="rc-mgb">任务类别：{{raskTypeName}}</a-col>
          <a-col :span="8" class="rc-mgb">报名时间：{{raskDetailInfo.enrollBeginDate}} 至 {{raskDetailInfo.enrollEndDate}}</a-col>
          <a-col :span="8" class="rc-mgb">任务时间：{{raskDetailInfo.workBeginDate}} 至 {{raskDetailInfo.workEndDate}}</a-col>
          <a-col :span="8" class="rc-mgb">预计人数：{{raskDetailInfo.needNum}}</a-col>
          <a-col :span="8" class="rc-mgb">预算金额：{{raskDetailInfo.budgetAmount===null?'--':raskDetailInfo.budgetAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb">
            任务状态：
            <span v-if="raskDetailInfo.status==='10'">待审核</span>
            <span v-if="raskDetailInfo.status==='11'">审核失败</span>
            <span v-if="raskDetailInfo.status==='13'">报名中</span>
            <span v-else-if="raskDetailInfo.status==='14'">报名截止</span>
            <span v-else-if="raskDetailInfo.status==='20'">进行中</span>
            <span v-else-if="raskDetailInfo.status==='30'">已完成</span>
            <span v-else-if="raskDetailInfo.status==='50'">已取消</span>
          </a-col>
          <a-col :span="8" class="rc-mgb">结算类型：{{raskDetailInfo.payMode==='10'?'单次结算':'多次结算'}}</a-col>
          <a-col :span="8" class="rc-mgb">任务描述：{{raskDetailInfo.content}}</a-col>
        </a-row>
      </div>
    </div>
    <div class="content">
      <a-tabs default-active-key="1" @change="callback">
        <!-- 报名人员 -->
        <a-tab-pane key="1" :tab="totalBmry">
          <div>
            <div class="bmry">
              <a-button type="primary" :disabled="raskDetailInfo.status==='13'?false:true" @click="invite">邀请人员</a-button>
            </div>
            <s-table
              ref="bmryTable"
              style="border-top:1px solid #F0F0F0;margin-top:16px"
              :columns="columnsBmry"
              :data="dataBmry"
              :rowKey="(record) => record.id"
            >
              <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
                <a @click="sign(record)" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'">用工</a>
                <a-divider type="vertical" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'" />
                <a @click="refuse(record)" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'">拒绝</a>
              </span>
              <span slot="userName" slot-scope="text,record">
                <a-popover title="">
                  <template slot="content">
                    <div class="pop-content">
                      <div>
                        <img class="img" :src="record.avatarUrl" alt="">
                      </div>
                      <div>
                        <div class="person"><span>{{record.userName}}</span><span>{{record.phoneNo}}</span></div>
                        <div class="construction">{{record.oneselfRemark}}</div>
                      </div>
                    </div>
                    <div class="tags">{{record.oneselfLable}}</div>
                  </template>
                  <a-button style="padding:0;" type="link">
                    {{record.userName}}
                  </a-button>
                </a-popover>
              </span>
              <span slot="signStatus" slot-scope="text">
                <div class="table-point" v-if="text==='10'"><span style="background:#1890FF"></span> 已报名</div>
                <div class="table-point" v-else-if="text==='92'"><span style="background:#52C41A"></span> 已拒绝</div>
                <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
              </span>
            </s-table>
            <!-- 发送邀请 -->
            <a-modal
              title="发送邀请"
              width="720px"
              :visible="visibleYq"
              :footer="null"
              @cancel="handleCancelYq"
            >
              <div class="fsyq-head">
                <div v-if="!isClickYg">
                  <div class="fsyq-title">请输入需要被邀请人的手机号码</div>
                  <div>
                    <a-input-search v-model="inviteUserPhone" style="width:360px;height:32px" placeholder="请输入手机号码" @search="onSearchInvite">
                      <a-button slot="enterButton">
                        搜索
                      </a-button>
                    </a-input-search>
                  </div>
                </div>
                <div class="user-info" v-if="hasInvitor">
                  <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
                  <div v-if="currentItem">{{currentItem.userName}}</div>
                </div>
              </div>
              <div class="no-invite" v-if="!hasInvitor&&stepTwoShowContentYq">
                <div>该用户暂未注册分时领英小程序平台</div>
                <div style="margin-top:20px"><a-button type="link" @click="sendDx">发送短信邀请</a-button></div>
              </div>
              <div class="common-opt">
                <a-button @click="handleCancelYq">取消</a-button>
                <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYq' :disabled="!hasInvitor" @click="handleOkYq">确认发送</a-button>
              </div>
            </a-modal>
          </div>
        </a-tab-pane>
        <!-- 用工人员 -->
        <a-tab-pane key="2" class="task" :tab="totalQyry" force-render>
          <div class="bmry">
            <a-button type="primary" @click="plSign" :disabled="!hasSelected">发起付款</a-button>
          </div>
          <s-table
            ref="qyryTable"
            style="border-top:1px solid #F0F0F0;margin-top:16px"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :columns="columnsQyry"
            :data="dataQyry"
            :rowKey="(record) => record.id"
          >
            <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
              <a v-if="record.signStatus==='20'" @click="cancelSign(record)">取消用工</a>
            </span>
            <span slot="signStatus" slot-scope="text">
              <div class="table-point" v-if="text==='20'"><span style="background:#1890FF"></span> 待确认</div>
              <div class="table-point" v-else-if="text==='30'"><span style="background:#52C41A"></span> 已确认</div>
              <div class="table-point" v-else-if="text==='93'"><span style="background:#F00"></span> 未接受</div>
              <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
            </span>
          </s-table>
          <!-- 签约确认单 -->
          <a-modal
            title="付款确认单"
            :visible="visible"
            width='650px'
            :footer="null"
            @cancel="handleCancel"
          >
            <ul class="model">
              <li>
                <span class="span">用工周期：</span>
                <div class="tag">
                  <a-range-picker @change="onChangeDate" />
                </div>
              </li>
              <li>
                <span class="span">用工人员：</span>
                <div class="tag">
                  <a-tag v-for="(item,index) in bmryParams.selectedTotalBmry" :key="index">{{item.userName}}</a-tag>
                  <span>共{{bmryParams.selectedTotalBmry.length}}人</span>
                </div>
              </li>
              <li v-for="(item,index) in currentSelectedBmry" :key="index">
                <span class="span">{{item.userName}}：</span>
                <div style="display:flex">
                  <div><a-input @change="userPayChange(item,index)" type="number" :maxLength="11" v-model="item.payAmount" style="width:200px" min="0" placeholder="请输入任务金额"></a-input></div>
                  <div class="mgt-5" v-if="isAllow">
                    费用金额{{item.fyje}}元,
                    <span>实际总金额为<span style="color:#FF4D4F">{{item.fyzje}}元</span></span>
                  </div>
                  <div class="mgt-5" style="color:red" v-else>
                    {{allowMessage}}
                  </div>
                </div>
              </li>
            </ul>
            <div class="operation">
              <a-button @click="handleCancel">取消</a-button>
              <a-button @click="handleOk" :loading="confirmLoading" :disabled="!isAllow" type="primary" style="margin-left:10px">确认</a-button>
            </div>
          </a-modal>
        </a-tab-pane>
        <!-- 付款信息 -->
        <a-tab-pane key="3" :tab="totalFkxx">
          <s-table
            style="border-top:1px solid #F0F0F0;margin-top:16px"
            ref="yfkTable"
            :columns="columnsYfkjsd"
            :data="dataYfkjsd"
            :rowKey="(record) => record.id"
          >
            <span slot="cycle" slot-scope="text, record">
              <span>{{record.payPeriodStartTime}} ~ {{record.payPeriodEndTime}}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a @click="payDetail(record)">付款详情</a>
              <a-divider type="vertical" />
              <a-button style="padding:0" type="link" @click="submitPay(record)" :disabled="(record.payStatus==='40'&&record.payAmount!==0)?false:true">提交结算</a-button>
            </span>
          </s-table>
          <!-- 详情 -->
          <a-modal
            title="付款详情"
            :visible="visibleTaskDetail"
            @cancel="visibleTaskDetail=false"
            width="1000px"
            class="detail-modal"
            :footer="null"
          >
            <s-table
              style="border-top:1px solid #F0F0F0;margin-top:16px"
              ref="yfkTableDetail"
              :columns="columnsWkjsd"
              :data="dataWkjsd"
              :rowKey="(record) => record.id"
            >
              <span slot="cycle" slot-scope="text, record">
                <span>{{record.payPeriodStartTime}} ~ {{record.payPeriodEndTime}}</span>
              </span>
              <span slot="action" slot-scope="text, record">
                <a @click="cancelPays(record)" v-if="record.payStatus==='10'">取消付款</a>
              </span>
            </s-table>
          </a-modal>
        </a-tab-pane>
        <!-- 任务审核 -->
        <a-tab-pane key="4" :tab="totalRwsh">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :lg="6" :md="8" :sm="12">
                <a-form-item label="用户名称">
                  <a-input style="width:200px" v-model="queryParam.userName" placeholder="请输入姓名"/>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="24">
                <a-form-item label="审核状态">
                  <a-select style="width:200px" placeholder="请选择审核状态" v-model="queryParam.auditStatus">
                    <a-select-option value="10">企业待审核</a-select-option>
                    <a-select-option value="20">已通过</a-select-option>
                    <a-select-option value="30">已驳回</a-select-option>
                    <a-select-option value="11">平台待审核</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button style="margin-left: 12px;" type="primary" @click="handleToSearchEnterprises">查询</a-button>
                  <a-button style="margin-left: 12px;" icon="reload" @click="resetSearchEnterprises">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form> 
          <div>
            <s-table
            style="border-top:1px solid #F0F0F0;margin-top:16px;"
            ref="rwshTable"
            :columns="columnsDsh"
            :data="dataDsh"
            :rowKey="(record) => record.id"
          >
            <span slot="cycle" slot-scope="text, record">
              <span>{{record.payPeriodStartTime}} ~ {{record.payPeriodEndTime}}</span>
            </span>
            <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
              <a @click="pass(record)" v-if="record.auditStatus==='10'">通过</a>
              <a-divider type="vertical" v-if="record.auditStatus==='10'" />
              <a @click="noPass(record)" v-if="record.auditStatus==='10'">驳回</a>
            </span>
            <div slot="items" slot-scope="text, record">
              <a @click="showPic(record)">查看</a>
              <!-- <a :href="item.fileUrl" target="blink" v-for="(item,index) in record.items" :key="index">
                <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:25px;height:25px;margin-right:10px;margin-bottom:10px">
                <span v-if="item.fileUrl.indexOf('exl')!==-1||item.fileUrl.indexOf('xlsx')!==-1||item.fileUrl.indexOf('docx')!==-1||item.fileUrl.indexOf('mp4')!==-1||item.fileUrl.indexOf('ppt')!==-1">下载</span>
                <span v-if="item.fileUrl.indexOf('pdf')!==-1">预览</span>
              </a> -->
              <a-modal
                title="图片查看"
                :visible="visiblePicture"
                :footer="null"
                @cancel="visiblePicture=false"
              >
                <a-carousel arrows>
                  <div
                    slot="prevArrow"
                    slot-scope="props"
                    class="custom-slick-arrow"
                    style="left: 10px;zIndex: 1"
                    :dots='position'
                  >
                    <a-icon type="left-circle" />
                  </div>
                  <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                    <a-icon type="right-circle" />
                  </div>
                  <div v-for="(item,index) in picList" :key="index">
                    <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('jpeg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:500px;height:400px;">
                    <div style="height:80px;line-height:80px;text-align:left;font-size:18px">任务结果描述：{{currentContent}}</div>
                  </div>
                </a-carousel>
              </a-modal>
            </div>
            </s-table>
            <a-modal
              title="驳回原因"
              :visible="visibleBh"
              @ok="handleSubmit"
              @cancel="handleCancelBh"
            >
              <a-form :form="formBh" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                <a-form-item label="驳回原因">
                  <a-textarea
                    :autoSize="{ minRows: 3, maxRows: 5 }"
                    :maxLength="100"
                    v-decorator="['errorInfo', { rules: [{ required: true, message: '请输入驳回原因!' }] }]"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import STable from '@/components/table/'
import moment from 'moment'
import {
  raskDetail,
  raskCancel,
  raskEnd,
  getWorkList,
  raskAssign,
  refuseAssign,
  cancelAssign,
  getRaskTypeName,
  taskWorkerPayItem,
  settlementStatistical,
  getPayDetails,
  taskWorkerPaySubmit,
  taskWorkerResult,
  getByWorkerIds,
  getUserByPhone,
  invitation,
  sendInvitationSms,
  invitationRecommendUser,
  getServiceRule,
  addPeriodPay,
  getOrderList,
  taskWorkerPayItemList,
  cancelPay,
  submitPeriodPay,
  fulFilTask
} from '@/api/api'
export default {
  name: 'raskDetail',
  components: {
    STable
  },
  data () {
    const cellStyle = {
      maxWidth: '120px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow:'ellipsis',
      cursor:'pointer',
    };
    return {
      queryParam: {
        userName: '',
        auditStatus: undefined
      },
      isClickYg: false,
      visibleTaskDetail: false,
      visiblePicture:false,
      visibleBh: false,
      columnsBmry: [
        {
          title: '姓名',
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' },
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text
        },
        {
          title: '来源',
          dataIndex: 'joinType',
          customRender: text => text=== '10' ? '报名' : '邀请'
        },
        {
          title: '用工状态',
          dataIndex: 'signStatus',
          customRender: text => {
            if (text === '10') {
              return '确认报名'
            }
            if (text === '91' || text === '92') {
              return '报名失败'
            }
            if (text === '20') {
              return '合作确认'
            }
            if (text === '30') {
              return '合作中'
            }
            if (text === '93' || text === '94' || text === '95' || text === '96') {
              return '合作失败'
            }
          }
        },
        {
          title: '原因',
          dataIndex: 'signStatus',
          customRender: text => {
            if (text === '10' || text === '20' || text === '30') {
              return '--'
            }
            if (text === '91') {
              return '用户取消报名'
            }
            if (text === '92') {
              return '企业拒绝'
            }
            if (text === '93') {
              return '工作者拒绝合作'
            }
            if (text === '94') {
              return '企业取消合作'
            }
            if (text === '95') {
              return '超时系统自动取消'
            }
            if (text === '96') {
              return '企业取消任务'
            }
          }
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ], //报名人员
      totalBmry: '报名人员',
      totalFkxx: '付款信息',
      totalRwsh: '任务审核',
      bmryParams: {
        selectedTotalBmry: [],
        prePay: 0,
        raskRash: 0,
        users: [],
        taskId: '',
        payPeriodEndTime: '',
        payPeriodStartTime: '',
      },
      taxPoint: '',
      selectedRowKeys: [],
      confirmLoading: false,
      confirmLoadinginvite: false,
      visible: false,
      visibleinvite: false,
      canCancel: true,
      columnsQyry: [
        {
          title: '姓名',
          dataIndex: 'userName',
          customRender: text => text===null?'--':text
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text
        },
        // {
        //   title: '任务金额',
        //   dataIndex: 'signAmount',
        //   customRender: text => text === null?'--':text.toFixed(2)
        // },
        {
          title: '用工状态',
          dataIndex: 'signStatus',
          scopedSlots: { customRender: 'signStatus' },
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ], //签约人员
      totalQyry: '用工人员',
      columnsDsh: [
        {
          title: '提交时间',
          dataIndex: 'createTime',
          defaultSortOrder: 'descend',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
          width: '120px'
        },
        {
          title: '用工周期',
          dataIndex: 'cycle',
          width: '200px',
          scopedSlots: { customRender: 'cycle' },
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          customRender: text => text===null?'--':text,
          width: '80px'
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo',
          customRender: text => text===null?'--':text,
          width: '100px'
        },
        {
          title: '任务结果',
          dataIndex: 'items',
          scopedSlots: { customRender: 'items' },
          width: '100px'
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatus',
          customRender: text => text === '10'?"企业待审核":text === '20'?"已通过":text === '11'?"平台待审核":"已驳回",
          width: '100px'
        },
        {
          title: '驳回原因',
          dataIndex: 'errorInfo',
          customRender: text => text===null?'--':text,
          width: '100px'
        },
        {
          title: '处理时间',
          dataIndex: 'auditTime',
          width: '120px'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: '130px',
          fixed: 'right'
        }
      ], //任务审核
      columnsYfkjsd: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '200px'
        },
        {
          title: '用工周期',
          dataIndex: 'cycle',
          width: '200px',
          scopedSlots: { customRender: 'cycle' },
          width: '250px'
        },
        {
          title: '付款人数',
          dataIndex: 'payNum',
          customRender: text => text === null?'--':text
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款状态',
          dataIndex: 'payStatus',
          customRender: text => text === '10' ? '提交成功' : text === '20' ? '复核成功' : text === '30' ? '付款成功' : text === '50' ? '任务结果处理中' : text === '60' ? '取消付款' : '待提交',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ], //预付款结算单
      yfkPre: null,
      yfkLater: null,
      notYfuJs: true,
      columnsWkjsd: [
        {
          title: '姓名',
          dataIndex: 'userName'
        },
        {
          title: '联系电话',
          dataIndex: 'phoneNo'
        },
        {
          title: '支付金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款状态',
          dataIndex: 'payStatus',
          customRender: text => text === '10' ? '等待上传任务结果' : text === '20' ? '待支付' : text === '30' ? '支付处理中' : text === '50' ? '取消付款' : text === '11' ? '已上传任务结果' : '支付成功',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ], //尾款结算单
      wkPre: null,
      wkLater: null,
      notWkJs: true,
      raskDetailInfo: null,
      raskTypeName: '',
      raskId: '',
      formLayout: 'horizontal',
      formBh: this.$form.createForm(this),
      record: null,
      expand: false,
      tjjsLoading: false,
      tjjsWkLoading: false,
      invitePhone: '',
      inviteUserList: [],
      disabledBtn1:true,
      disabledBtn2:true,
      visibleYq: false,
      confirmLoadingYq: false,
      yfje: 0,
      rwje: 0,
      inputYfje: 0,
      hasInvitor: false, // 是否查询到邀请用户
      visibleYg: false, //确认用工
      currentItem: null,
      searchInviteUser: '',
      inviteUserPhone: '',
      stepTwoShowContentYq: false,
      picList: [],
      currentSelectedBmry: [],
      currentContent: '',
      isAllow:false,
      allowMessage: ''
    }
  },
  computed: {
    // 费用
    fy () {
      if (this.rwje) {
        return (this.rwje*this.taxPoint).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 总金额
    allFy () {
      if (this.rwje) {
        return (this.rwje*this.taxPoint+Number(this.rwje)).toFixed(2)
      } else {
        return 0.00
      }
    },
    wk () {
      if (this.inputYfje&&this.rwje) {
        return (this.rwje-this.inputYfje).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 报名人员是否已选择
    hasSelected() {
      if (this.raskDetailInfo.status==='20'||this.raskDetailInfo.status==='30'||this.raskDetailInfo.status==='50') {
        return false
      }
      return this.selectedRowKeys.length > 0;
    },
    // 平均每人任务金额
    avregeCash () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        return (this.bmryParams.raskRash / this.bmryParams.selectedTotalBmry.length).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 费用
    fyCash () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        console.log(this.bmryParams.raskRash,this.taxPoint)
        return (this.bmryParams.raskRash * this.taxPoint).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 平均每人预付金额
    prePayCash () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        return (this.bmryParams.raskRash * this.bmryParams.prePay / this.bmryParams.selectedTotalBmry.length / 100).toFixed(2)
      } else {
        return 0.00
      }
    },
    // 实际金额
    localPay () {
      if (this.bmryParams.selectedTotalBmry.length > 0) {
        return (Number(this.bmryParams.raskRash) + (this.bmryParams.raskRash * this.taxPoint)).toFixed(2)
      } else {
        return 0.00
      }
      
    },
    // 统计
    tjPrePay () {
      let s = '预付款' + (this.prePayCash*this.selectedRowKeys.length).toFixed(2) + ' 剩余尾款' + ((this.avregeCash - this.prePayCash)*this.selectedRowKeys.length).toFixed(2)
      return s
    }
  },
  created () {
    this.raskId = window.sessionStorage.getItem('raskId')
    this.getRaskDetail()
    this.getYfkPre()
    this.getWkPre()
    this.getWkLater()
    this.getYfkLater()
    this.dataYfkjsd()
    this.dataDsh()
  },
  methods: {
    // 查询
    handleToSearchEnterprises () {
      this.$refs.rwshTable.refresh(true)
    },
    // 重置
    resetSearchEnterprises () {
      this.queryParam = {
        userName: '',
        auditStatus: undefined
      }
      this.handleToSearchEnterprises()
    },
    // 取消付款
    cancelPays(record) {
      console.log(record)
      let that = this
      this.$confirm({
        title: '确定取消付款？',
        onOk() {
          return new Promise((resolve, reject) => {
            cancelPay(record.id).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.$refs.yfkTableDetail.refresh(true)
                that.$refs.yfkTable.refresh(true)
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 提交结算
    submitPay(record) {
      let that = this
      this.$confirm({
        title: '确定提交结算？',
        onOk() {
          return new Promise((resolve, reject) => {
            submitPeriodPay({payId:record.id,taskId:that.raskId}).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.$refs.yfkTable.refresh(true)
                window.sessionStorage.setItem('submenu','/settlementDetail')
                window.sessionStorage.setItem('taskInfo',JSON.stringify(res.result))
                window.location.reload(true)
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 关闭任务
    closeRask(record) {
      console.log(record)
      let that = this
      this.$confirm({
        title: '确定关闭任务？',
        onOk() {
          return new Promise((resolve, reject) => {
            fulFilTask(that.raskId).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.ret();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 查看付款详情
    payDetail(record) {
      this.record = record
      this.visibleTaskDetail = true
      this.$refs.yfkTableDetail.refresh(true)
    },
    // 输入金额
    userPayChange (item,index) {
      getServiceRule(item).then(res =>  {
        if (res.success) {
          this.isAllow = true
          item.fyje = res.result.feeAmount
          item.fyzje = res.result.totalAmount
          this.$set(this.currentSelectedBmry,index,item)
        } else {
          this.isAllow = false
          this.allowMessage = res.message
          this.$message.error(res.message)
        }
      })
      
    },
    // 选择日期
    onChangeDate(date, dateString) {
      console.log(date, dateString);
      this.bmryParams.payPeriodStartTime = dateString[0]
      this.bmryParams.payPeriodEndTime = dateString[1]
    },
    // 查看图片
    showPic(record) {
      this.currentContent = record.content
      this.picList = record.items
      this.visiblePicture=true
    },
    // 获取费率
    getPoint () {
      getServiceRule().then(res =>  {
        // console.log(res)
      })
    },
    // 获取任务类别名称
    getRaskTypeName (id) {
      getRaskTypeName(id).then(res => {
        if (res.success) {
          this.raskTypeName = res.result.industryName
        }
      })
    },
    // 获取任务详情
    getRaskDetail () {
      raskDetail({id:this.raskId}).then(res => {
        // console.log('任务详情',res)
        if (res.success) {
          this.raskDetailInfo = res.result
          if (this.raskDetailInfo.status==='20'||this.raskDetailInfo.status==='30'||this.raskDetailInfo.status==='50') {
            this.canCancel = false
          }
          this.getRaskTypeName(this.raskDetailInfo.taskType)
        }
      })
    },
    // 取消任务
    cancelRask (record) {
      let that = this
      this.$confirm({
        title: '确定取消该任务?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          raskCancel(that.raskId).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.ret()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 结束报名
    raskEnd (record) {
      let that = this
      this.$confirm({
        title: '确定结束报名?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          raskEnd(that.raskId).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.ret()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 返回任务详情
    ret () {
      window.localStorage.setItem('submenu','raskManage')
      this.$router.push('/raskManage')
    },
    // 查询
    handleToSearchEnterprise () {},
    // 重置
    resetSearchEnterprise () {},
    // 选择标签页
    callback(key) {
      if (key === '3') {
        this.getRaskDetail()
        this.getYfkPre()
        this.getWkPre()
        this.getWkLater()
        this.getYfkLater()
        this.dataYfkjsd()
        this.$refs.yfkTable.refresh(true)
      } else if (key === '1' || key === '2') {
        this.getRaskDetail()
        setTimeout(()=>{
          this.$refs.bmryTable.refresh(true)
          this.$refs.qyryTable.refresh(true)
        },100)
      } else if (key === '4') {
        this.getRaskDetail()
        this.dataDsh()
        setTimeout(()=>{
          this.$refs.rwshTable.refresh(true)
        },100)
      }
    },
    // 获取报名人员getBmryList
    dataBmry (parameter) {
      return getWorkList(Object.assign({taskId:this.raskId,signStatus:null},parameter)).then(res => {
        // console.log(res)
        if (res.success) {
          this.totalBmry = '报名人员(' + res.result.totalCount + ')'
        }
        return res.result
      })
    },
    // 获取签约人员getQyryList
    dataQyry (parameter) {
      return getWorkList(Object.assign({taskId:this.raskId,signStatus:'20'},parameter)).then(res => {
        if (res.success) {
          this.totalQyry = '用工人员(' + res.result.totalCount + ')'
          if(res.result.data.length>0) {
            res.result.data.forEach(item=>{
              if(item.signStatus==='30') {
                this.canCancel = false
              }
            })
          }
        }
        // console.log(res)
        return res.result
      })
    },
    // 获取预付款列表getYfkList
    dataYfkjsd (parameter) {
      return getOrderList(Object.assign({taskId:this.raskId},parameter)).then(res => {
        if (res.success) {
          this.totalFkxx = '付款信息(' + res.result.totalCount + ')'
        }
        return res.result
      })
    },
    // 获取尾款列表getWkList
    dataWkjsd (parameter) {
      return taskWorkerPayItemList(Object.assign({payId:this.record.id},parameter)).then(res => {
        return res.result
      })
    },
    // 获取预付款统计
    getYfkPre () {
      settlementStatistical({taskId: this.raskId,payType:'10'}).then(res => {
        if (res.success) {
          this.yfkPre = res.result
        }
      })
    },
    getYfkLater () {
      getPayDetails({taskId: this.raskId,payType:'10'}).then(res => {
        // console.log(res)
        if (res.success) {
          this.yfkLater = res.result
          this.notYfuJs = this.yfkLater === null ? true : false
        }
      })
    },
    // 获取尾款统计
    getWkPre () {
      settlementStatistical({taskId: this.raskId,payType:'20'}).then(res => {
        if (res.success) {
          this.wkPre = res.result
        }
      })
    },
    getWkLater () {
      getPayDetails({taskId: this.raskId,payType:'20'}).then(res => {
        if (res.success) {
          this.wkLater = res.result
          this.notWkJs = this.wkLater === null ? true : false
        }
      })
    },
    // 获取审核列表getShList
    dataDsh (parameter) {
      return getByWorkerIds(Object.assign({taskId:this.raskId},parameter,this.queryParam)).then(res => {
        if (res.success) {
          this.totalRwsh = '任务审核(' + res.result.totalCount + ')'
        }
        return res.result
      })
    },
    // 报名人员操作
    onSelectChange(selectedRowKeys,selectedRows) {
      let sek = []
      let ser = []
      selectedRows.forEach((item,index) => {
        if (item.signStatus==='30') {
          item.payAmount= ''
          item.workerId = item.id
          sek.push(item.id)
          ser.push(item)
        }
      })
      this.selectedRowKeys = sek;
      this.bmryParams.selectedTotalBmry = ser
      this.currentSelectedBmry = JSON.parse(JSON.stringify(ser))
    },
    // 签约
    sign(record) {
      this.isClickYg = true
      this.hasInvitor = true
      this.currentItem =  record
      this.selectedRowKeys.length = 1
      this.bmryParams.selectedTotalBmry = []
      this.visibleYq = true
      this.bmryParams.selectedTotalBmry[0] = record
    },
    // 拒绝
    refuse(record) {
      let that = this
      this.$confirm({
        title: '确定拒绝用工?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          refuseAssign({id:record.id}).then(res => {
            if (res.success) {
              that.getRaskDetail()
              that.$refs.bmryTable.refresh(true)
              that.$refs.qyryTable.refresh(true)
              that.$message.success(res.message)
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 批量签约
    plSign () {
      this.visible = true
    },
    fiexed(e) {
      this.bmryParams.prePay = Number(this.bmryParams.prePay).toFixed(0)
    },
    // 发起付款
    handleOk () {
      if (this.bmryParams.payPeriodEndTime===''||this.bmryParams.payPeriodEndTime==='') {
        this.$message.error('请选择周期')
        return
      }
      let isOk = false
      this.currentSelectedBmry.forEach(item=>{
        if (item.payAmount===''||Number(item.payAmount)<=0) {
          this.$message.error('任务金额不能为空或者小于等于零')
          isOk = false
          return
        }
        isOk = true
      })
      if (isOk===false) {
        return
      }
      this.bmryParams.taskId = this.raskId
      this.bmryParams.users = this.currentSelectedBmry
      this.confirmLoading = true
      addPeriodPay(this.bmryParams).then(res => {
        this.confirmLoading = false
        if (res.success) {
          this.$message.success(res.message)
          this.getRaskDetail()
          this.$refs.bmryTable.refresh(true)
          this.$refs.qyryTable.refresh(true)
          this.handleCancel()
          this.countDown()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    // 付款后的提示
    countDown() {
      let secondsToGo = 50;
      const modal = this.$success({
        title: '操作提示',
        content: `如想查看用工周期付款单，请进入【付款信息】中查看。`,
      });
      setTimeout(() => {
        modal.destroy();
      }, secondsToGo * 1000);
    },
    // 邀请
    invite () {
      this.visibleYq = true
      this.isClickYg = false
    },
    tofixed () {
      this.inputYfje = Number(this.inputYfje).toFixed(2)
    },
    // 搜索
    onSearchInvite (value) {
      if ((/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.inviteUserPhone)) {
        getUserByPhone(this.inviteUserPhone).then(res => {
          if (res.result.length>0&&res.success) {
            this.currentItem = res.result[0]
            this.hasInvitor = true
          } else {
            this.hasInvitor = false
            this.stepTwoShowContentYq = true
          }
        })
      } else {
        this.$message.error('手机号格式不正确')
      }
    },
    // 任务金额
    onChangeRwje () {
      if (this.rwje!==0) {
        this.inputYfje = (this.rwje * this.yfje).toFixed(2)
      }
    },
    // 单选预付金额
    onChangeYfje () {
      if (this.yfje!==0) {
        this.inputYfje = (this.yfje * this.rwje).toFixed(2)
      }
    },
    // 发送短信
    sendDx () {
      if ((/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.inviteUserPhone)) {
        sendInvitationSms(this.inviteUserPhone).then(res => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancelYq()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('手机号格式不正确')
      }
    },
    // 确认发送
    handleOkYq () {
      this.confirmLoadingYq = true
      if (!this.isClickYg) {
        invitationRecommendUser(Object.assign(this.currentItem,{taskId:this.raskId})).then(res => {
          this.confirmLoadingYq = false
          if (res.success) {
            this.$message.success(res.message)
            this.$refs.qyryTable.refresh(true)
            this.$refs.bmryTable.refresh(true)
            this.handleCancelYq()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        raskAssign(Object.assign({models: [{id: this.currentItem.id}]},{id:this.raskId})).then(res => {
          this.confirmLoadingYq = false
          if (res.success) {
            this.$message.success(res.message)
            this.$refs.qyryTable.refresh(true)
            this.$refs.bmryTable.refresh(true)
            this.handleCancelYq()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 取消邀请
    handleCancelYq () {
      this.visibleYq = false
      this.inviteUserPhone = ''
      this.rwje = 0
      this.inputYfje = 0
      this.yfje = 0.3
      this.hasInvitor = false
      this.stepTwoShowContentYq = false
    },

    // 签约人员
    // 取消签约
    cancelSign (record) {
      let that = this
      this.$confirm({
        title: '确定取消用工?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          cancelAssign({id:record.id}).then(res => {
            if (res.success) {
              that.getRaskDetail()
              that.$message.success(res.message)
              that.$refs.qyryTable.refresh(true)
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },

    // 付款信息
    callbackFk (key) {
    },
    // 提交预付款
    subYfk () {
      this.tjjsLoading = true
      taskWorkerPaySubmit({taskId:this.raskId,payType:'10'}).then(res => {
        this.tjjsLoading = false
        if (res.success) {
          this.$message.success(res.message)
          this.getRaskDetail()
          this.$refs.yfkTable.refresh(true)
          this.getYfkLater()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 提交尾款
    subWk () {
      this.tjjsWkLoading = true
      taskWorkerPaySubmit({taskId:this.raskId,payType:'20'}).then(res => {
        this.tjjsWkLoading = false
        if (res.success) {
          this.$message.success(res.message)
          this.getRaskDetail()
          this.getWkLater()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //  待审核
    //  通过
    pass (record) {
      let that = this
      this.$confirm({
        title: '确定通过?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          taskWorkerResult({id:record.id,auditStatus:'20'}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.$refs.rwshTable.refresh(true)
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
        }
      });
    },
    // 驳回
    noPass (record) {
      this.record = record
      this.visibleBh = true
    },
    handleSubmit(e) {
      e.preventDefault();
      this.formBh.validateFields((err, values) => {
        if (!err) {
          let params = Object.assign(values,{id:this.record.id,auditStatus: '30'})
          taskWorkerResult(params).then(res => {
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.rwshTable.refresh(true)
              this.handleCancelBh()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelBh () {
      this.visibleBh = false
      this.formBh.resetFields()
    },
  }
}
</script>
<style scoped lang="less">
.header{
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  .ret{
    color: rgba(0,0,0,0.65);
    cursor: pointer;
  }
}
.content{
  width: 98%;
}
.header-one{
  padding: 27px 24px;
  border: 1px solid #F0F0F0;
  margin-bottom: 16px;
  // border-left: 0;
  // border-right: 0;
  // border-bottom: 0;
}
.rask-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rt-left{
    display: flex;
    align-items: flex-end;
    .rt-left-title{
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
    }
    .rt-left-time{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.rask-content{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .rc-mgb{
    margin-top: 24px;
    white-space: nowrap;
    &:last-child{
      width: 100%;
      text-align: justify;
      white-space: normal;
    }
    span{
      color:#1890FF;
    }
  }
}
.search{
  width: 100%;
  padding: 16px 0;
  // display: flex;
  align-items: center;
}
.search-table{
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.model{
  padding: 0;
  list-style: none;
  li{
    display: flex;
    align-items: flex-start;
    margin-bottom:20px;
    .span{
      width: 80px;
    }
    .mgt-5{
      margin-top: 5px;
      margin-left:5px;
    }
    .tag{
      width: 360px;
      display: flex;
      flex-wrap: wrap;
      span{
        margin-bottom: 10px;
      }
    }
  }
}
.fkxx-title{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 0 25px 0;
}
.fkxx-card{
  width: 500px;
  // height: 182px;
  padding: 20px 24px;
  border: 1px solid #F0F0F0;
  .fkxxc-title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
  }
  .fkxxc-nn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fkxxc-nn-left{
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
    }
    .fkxxc-nn-right{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      &::before{
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #F5222D;
        border-radius: 50%;
        content: '';
        margin-right: 5px;
      }
    }
  }
  .fkxx-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.85);
    span{
      width: 160px;
    }
  }
}
.table-point{
  display: flex;
  align-items: center;
  // justify-content: center;
  span{
    width: 4px;
    height: 4px;
    background: #1890FF;
    border-radius: 50%;
    margin-right: 4px;
  }
}
.shrw{
  list-style: none;
  padding: 0;
  li{
    margin: 0 0 20px 0;
  }
}
.common-img{
  width: 26px;
  height: 30px;
  margin: 0 10px 0 0;
}
.showExpand{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-list{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li{
    margin: 10px 10px 0 0;
  }
}
.bmry{
  display: flex;
  justify-content: space-between;
}
.fsyq-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .fsyq-title{
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 0 0 14px 0;
  }
  .user-info{
    margin: 24px 0;
    .img{
      width: 100px;
      height: 100px;
      border-radius: 3px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 100px;
      font-size: 40px;
      background: #DDDDDD;
      border-radius: 50%;
    }
    div{
      text-align: center;
      color: rgba(32, 32, 32, 0.65);
      font-size: 14px;
    }
  }
}
.common-content{
  .cc-title{
    padding: 18px 0;
    border-bottom: 1px solid #F0F0F0;
    font-size: 18px;
    color: #000000;
  }
  .cc-desc{
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 24px 0 0 0;
  }
  .cc-content{
    display: flex;
    align-items: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
    margin:24px 0 0 0;
  }
  .cc-footer{
    margin: 24px 0;
    padding: 20px 80px;
    width: 640px;
    // height: 95px;
    background: #FAFAFA;
    border-radius: 3px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ccf-left,.ccf-right{
      color: #333333;
      font-size: 16px;
      span{
        font-size: 24px;
        color: #FF4D4F;
      }
      div{
        &:last-child{
          margin: 10px 0 0 0;
          font-size: 14px;
          color: rgba(32, 32, 32, 0.65);
        }
      }
    }
    .ccf-right{
      margin-left: 100px;
    }
  }
}
.common-opt{
  text-align: center;
  margin: 20px 0 20px 0;
}
.no-invite{
  margin: 17px 0 77px 0;
  text-align: center;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.65);
}
.pop-content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #E1E4E8;
  .img{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .person{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
    span{
      &:last-child{
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        margin-left: 10px;
      }
    }
  }
  .construction{
    width: 230px;
    text-align: justify;
    font-size: 14px;
  }
}
.operation{
  display: flex;
  justify-content: flex-end;
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots{
  display: none !important;
}
</style>
